import { enquiryActionType } from "../actionTypes"

// Define an initial state object with default values
const initialState = {
  enquiries: [],
  enquiry: {
    comments: [],
    first_name: "",
    last_name: "",
    closest_location: "",
    enquiry_type: "",
    work_email: "",
    project_reference: "",
    sample_id: "",
    subject: ""
  },
  isCreateEnquirySuccess: false,
  isCreateCommentEnquirySuccess: false,
  isgetEnquiryByIdSuccess: false,
  isGetEnquiriesSuccess: false,
}

// Define the main reducer function which takes in the current state and an action parameter
const enquiry = (state = initialState, action) => {
  switch (action.type) {
    // Case for GET_LIST_ENQUIRY action type.
    case enquiryActionType.GET_LIST_ENQUIRY:
      return {
        ...state,
        enquiries: action.payload,
      }
    // Case for GET_ENQUIRY_BY_ID action type.
    case enquiryActionType.GET_ENQUIRY_BY_ID:
      return {
        ...state,
        enquiry: action.payload,
      }
    // Case for CREATE_ENQUIRY_SUCCESS action type.
    case enquiryActionType.CREATE_ENQUIRY_SUCCESS:
      return {
        ...state,
        isCreateEnquirySuccess: action.payload,
      }
    // Case for GET_ENQUIRY_BY_ID_SUCCESS action type.
    case enquiryActionType.GET_ENQUIRY_BY_ID_SUCCESS:
      return {
        ...state,
        isgetEnquiryByIdSuccess: action.payload,
      }
    // Case for CREATE_COMMENT_ENQUIRY_SUCCESS action type.
    case enquiryActionType.CREATE_COMMENT_ENQUIRY_SUCCESS:
      return {
        ...state,
        isCreateCommentEnquirySuccess: action.payload,
      }
    // Case for GET_ENQUIRIES_SUCCESS action type.
    case enquiryActionType.GET_ENQUIRIES_SUCCESS:
      return {
        ...state,
        isGetEnquiriesSuccess: action.payload,
      }
    // Default fallback case returns the current state.
    default:
      return state
  }
}

// Exporting auth reducer function as default.
export default enquiry