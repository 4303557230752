import { ags4ActionType } from "store/actionTypes"

// Initial state of ags4 reducer
const initialState = {
    isImportSuccess: false, // Flag to keep track of AGS4 import success status
    isExist: false // Flag to keep track of record existence in AGS4
};

// AGS4 reducer function
const ags4 = (state = initialState, action) => {
    switch (action.type) {
        case ags4ActionType.IMPORT_SUCCESS: // If AGS4 import is success
            return {
                ...state,
                isImportSuccess: action.payload // Set the isImportSuccess flag to true
            }
        case ags4ActionType.CHECK_EXIST_SUCCESS: // If checking record existence in AGS4 is success
            return {
                ...state,
                isExist: action.payload // Set the isExist flag to true
            }
        default:
            return state
    }
}
export default ags4; // Exporting AGS4 reducer function as default export