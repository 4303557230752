import { trackingActionType } from "store/actionTypes"

// Setting initial state values.
const initialState = {
  listTracking: [],
  dataTrackingPage: {},
  isGetListTrackingPageSuccess: false,
  isGetListTrackingPageError: false,
  isGetDataTrackingPageSuccess: false,
  isGetDataTrackingPageError: false
}

// Defining tracking reducer as a function with two parameters.
const tracking = (state = initialState, action) => {
  switch (action.type) {
    // Case for GET_LIST_TRACKING action type.
    case trackingActionType.GET_LIST_TRACKING:
      return {
        ...state,
        listTracking: action.payload
      }
    // Case for GET_LIST_TRACKING_SUCCESS action type.
    case trackingActionType.GET_LIST_TRACKING_SUCCESS:
      return {
        ...state,
        isGetListTrackingPageSuccess: action.payload,
      }
    // Case for GET_LIST_TRACKING_ERROR action type.
    case trackingActionType.GET_LIST_TRACKING_ERROR:
      return {
        ...state,
        isGetListTrackingPageError: action.payload,
      }
    // Case for GET_DATA_TRACKING_PAGE action type.
    case trackingActionType.GET_DATA_TRACKING_PAGE:
      return {
        ...state,
        dataTrackingPage: action.payload
      }
    // Case for GET_DATA_TRACKING_PAGE_SUCCESS action type.
    case trackingActionType.GET_DATA_TRACKING_PAGE_SUCCESS:
      return {
        ...state,
        isGetDataTrackingPageSuccess: action.payload,
      }
    // Case for GET_DATA_TRACKING_PAGE_ERROR action type.
    case trackingActionType.GET_DATA_TRACKING_PAGE_ERROR:
      return {
        ...state,
        isGetDataTrackingPageError: action.payload,
      }
    // Default fallback case returns the current state.
    default:
      return state
  }
}

// Exporting auth reducer function as default.
export default tracking