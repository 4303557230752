import { testingRequestActionType } from "store/actionTypes"

// Initial State of Reducer
const initialState = {
  testingRequests: [],
  testingDetail: [],
  unscheduledSamples: [],
  isGetTestingDetailSuccess: false,
  isGetListTestingRequestSuccess: false,
  isGetListUnscheduledSamplesSuccess: false,
  isCreateScheduleSuccess: false,
  isUpdateTestingDetailSuccess: false,
  isConfirmDataSuccess: false
}

// Reducer Function
const schedule = (state = initialState, action) => {
  switch (action.type) {
    // Case for GET_TESTING_REQUESTS action type.
    case testingRequestActionType.GET_TESTING_REQUESTS:
      return {
        ...state,
        testingRequests: action.payload
      }
    // Case for GET_TESTING_REQUESTS_SUCCESS action type.
    case testingRequestActionType.GET_TESTING_REQUESTS_SUCCESS:
      return {
        ...state,
        isGetListTestingRequestSuccess: action.payload,
      }
    // Case for GET_UNSCHEDULED_SAMPLES_SUCCESS action type.
    case testingRequestActionType.GET_UNSCHEDULED_SAMPLES_SUCCESS:
      return {
        ...state,
        isGetListUnscheduledSamplesSuccess: action.payload,
      }
    // Case for CREATE_TESTING_REQUEST action type.
    case testingRequestActionType.CREATE_TESTING_REQUEST:
      return {
        ...state,
        isLoading: action.payload
      }
    // Case for CREATE_TESTING_REQUEST_SUCCESS action type.
    case testingRequestActionType.CREATE_TESTING_REQUEST_SUCCESS:
      return {
        ...state,
        isCreateScheduleSuccess: action.payload
      }
    // Case for GET_TESTING_REQUEST action type.
    case testingRequestActionType.GET_TESTING_REQUEST:
      return {
        ...state,
        testingDetail: action.payload
      }
    // Case for GET_TESTING_REQUEST_SUCCESS action type.
    case testingRequestActionType.GET_TESTING_REQUEST_SUCCESS:
      return {
        ...state,
        isGetTestingDetailSuccess: action.payload,
      }
    // Case for UPDATE_TESTING_REQUEST_SUCCESS action type.
    case testingRequestActionType.UPDATE_TESTING_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdateTestingDetailSuccess: action.payload
      }
    // Case for CONFIRM_DATA_SUCCESS action type.
    case testingRequestActionType.CONFIRM_DATA_SUCCESS:
      return {
        ...state,
        isConfirmDataSuccess: action.payload
      }
    // Case for GET_UNSCHEDULED_SAMPLES action type.
    case testingRequestActionType.GET_UNSCHEDULED_SAMPLES:
      return {
        ...state,
        unscheduledSamples: action.payload
      }
    // Default fallback case returns the current state.
    default:
      return state
  }
}

// Exporting auth reducer function as default.
export default schedule