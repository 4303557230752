export const authActionType = {
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  SEND_EMAIL_FORGOT_PASSWORD_SUCCESS: "SEND_EMAIL_FORGOT_PASSWORD_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  REMEMBER_ME: "REMEMBER_ME",
  SET_FORGOT_PASSWORD_DATA: "SET_FORGOT_PASSWORD_DATA",
  CHANGE_PASSWORD_FORGOT_PASSWORD_SUCCESS:
    "CHANGE_PASSWORD_FORGOT_PASSWORD_SUCCESS",
  LOGOUT: "LOGOUT",
  ERROR_LOGIN: "ERROR_LOGIN",
  ERROR_SEND_EMAIL_FORGOT_PASSWORD: "ERROR_SEND_EMAIL_FORGOT_PASSWORD",
  ERROR_CHANGE_PASSWORD_FORGOT_PASSWORD:
    "ERROR_CHANGE_PASSWORD_FORGOT_PASSWORD",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
  FIRST_TIME_RESET_PASSWORD_SUCCESS: "FIRST_TIME_RESET_PASSWORD_SUCCESS",
  ERROR_FIRST_TIME_RESET_PASSWORD: "ERROR_FIRST_TIME_RESET_PASSWORD",
  SET_FIRST_TIME_RESET_PASSWORD_DATA: "SET_FIRST_TIME_RESET_PASSWORD_DATA",
  GET_BY_ID: "GET_BY_ID",
  GET_MY_ACCOUNT: "GET_MY_ACCOUNT",
  ERROR_MY_ACCOUNT: "ERROR_MY_ACCOUNT",
  UPDATE_MY_ACCOUNT_SUCCESS: "UPDATE_MY_ACCOUNT_SUCCESS",
  CHANGE_PASSWORD_MY_ACCOUNT_SUCCESS: "CHANGE_PASSWORD_MY_ACCOUNT_SUCCESS",
  ERROR_CHANGE_PASSWORD_MY_ACCOUNT: "ERROR_CHANGE_PASSWORD_MY_ACCOUNT",

  REGISTER_SIZE_DATA: "REGISTER_SIZE_DATA",
  INIT_SIZE_DATA: "INIT_SIZE_DATA",
  REGISTER_SIZE_SUCCESS: "REGISTER_SIZE_SUCCESS",
  GET_SIZE_FOR_PRINT: "GET_SIZE_FOR_PRINT",
  ERROR_REGISTER_SIZE: "ERROR_REGISTER_SIZE",

  GET_BUSINESS: "GET_BUSINESS",
  ERROR_BUSINESS: "ERROR_BUSINESS",
  UPDATE_BUSINESS_SUCCESS: "UPDATE_BUSINESS_SUCCESS",
}

export const appActionType = {
  SET_LOADING: "SET_LOADING",
  SET_ALERT: "SET_ALERT",
  REMOVE_ALERT: "REMOVE_ALERT",
  RELOAD_MESSAGE: "RELOAD_MESSAGE",
}

export const userActionType = {
  GET_LIST: "GET_LIST",
  GET_BY_ID: "GET_BY_ID",
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ERROR_ADD_USER: "ERROR_ADD_USER",
  GET_LIST_USERS_SUCCESS: "GET_LIST_USERS_SUCCESS",
  GET_ASSIGNABLE_USERS_LIST: "GET_ASSIGNABLE_USERS_LIST"
}

export const projectActionType = {
  REGISTER_PROJECT_DATA: "REGISTER_PROJECT_DATA",
  INIT_REGISTER_PROJECT_DATA: "INIT_REGISTER_PROJECT_DATA",
  GET_LIST: "GET_LIST",
  PROJECT_REGISTER_SUCCESS: "PROJECT_REGISTER_SUCCESS",
  ERROR_PROJECT_REGISTER: "ERROR_PROJECT_REGISTER",
  GET_TESTING_SERVICE: "GET_TESTING_SERVICE",
  GET_PROJECT: "GET_PROJECT",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  ERROR_UPDATE_PROJECT: "ERROR_UPDATE_PROJECT",
  GET_PROJECT_BY_ID: "GET_PROJECT_BY_ID",
  GET_LIST_PROJECT_SUCCESS: "GET_LIST_PROJECT_SUCCESS",
  GET_PROJECT_BY_ID_SUCCESS: "GET_PROJECT_BY_ID_SUCCESS",
  GET_LIST_LAST_UPDATE_PROJECT_SUCCESS: "GET_LIST_LAST_UPDATE_PROJECT_SUCCESS",
  GET_LIST_LAST_UPDATE_PROJECT: "GET_LIST_LAST_UPDATE_PROJECT",
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
  LIST_SAMPLE_ID_IS_EXIST: "LIST_SAMPLE_ID_IS_EXIST",
  LIST_SAMPLE_ID_NO_EXIST: "LIST_SAMPLE_ID_NO_EXIST",
}

export const materialActionType = {
  GET_MATERIALS: "GET_MATERIALS",
}

export const testingRequestActionType = {
  CREATE_TESTING_REQUEST: "CREATE_TESTING_REQUEST",
  CREATE_TESTING_REQUEST_SUCCESS: "CREATE_TESTING_REQUEST_SUCCESS",
  CREATE_TESTING_REQUEST_ERROR: "CREATE_TESTING_REQUEST_ERROR",
  GET_TESTING_REQUESTS: "GET_TESTING_REQUESTS",
  GET_TESTING_REQUESTS_SUCCESS: "GET_TESTING_REQUESTS_SUCCESS",
  GET_TESTING_REQUEST: "GET_TESTING_REQUEST",
  GET_TESTING_REQUEST_SUCCESS: "GET_TESTING_REQUEST_SUCCESS",
  UPDATE_TESTING_REQUEST_SUCCESS: "UPDATE_TESTING_REQUEST_SUCCESS",
  CONFIRM_DATA_SUCCESS: "CONFIRM_DATA_SUCCESS",
  GET_UNSCHEDULED_SAMPLES: "GET_UNSCHEDULED_SAMPLES",
  GET_UNSCHEDULED_SAMPLES_SUCCESS: "GET_UNSCHEDULED_SAMPLES_SUCCESS"
}

export const modalActionType = {
  SET_MODAL_ADD_BARCODE_SHOW: "SET_MODAL_ADD_BARCODE_SHOW",
  SET_MODAL_OPTION_SIZE_SHOW: "SET_MODAL_OPTION_SIZE_SHOW",
  SET_MODAL_CONFIRM_REFRESH_SHOW: "SET_MODAL_CONFIRM_REFRESH_SHOW",
}

export const trackingActionType = {
  GET_LIST_TRACKING: "GET_LIST_TRACKING",
  GET_LIST_TRACKING_SUCCESS: "GET_LIST_TRACKING_SUCCESS",
  GET_LIST_TRACKING_ERROR: "GET_LIST_TRACKING_ERROR",
  GET_DATA_TRACKING_PAGE: "GET_DATA_TRACKING_PAGE",
  GET_DATA_TRACKING_PAGE_SUCCESS: "GET_DATA_TRACKING_PAGE_SUCCESS",
  GET_DATA_TRACKING_PAGE_ERROR: "GET_DATA_TRACKING_PAGE_ERROR",
}

export const enquiryActionType = {
  GET_LIST_ENQUIRY: "GET_LIST_ENQUIRY",
  GET_ENQUIRY_BY_ID: "GET_ENQUIRY_BY_ID",
  CREATE_ENQUIRY_SUCCESS: "CREATE_ENQUIRY_SUCCESS",
  ERROR_CREATE_ENQUIRY: "ERROR_CREATE_ENQUIRY",
  CREATE_COMMENT_ENQUIRY_SUCCESS: "CREATE_COMMENT_ENQUIRY_SUCCESS",
  GET_ENQUIRY_BY_ID_SUCCESS: "GET_ENQUIRY_BY_ID_SUCCESS",
  ERROR_ENQUIRY: "ERROR_ENQUIRY",
  ERROR_CREATE_COMMENT_ENQUIRY: "ERROR_CREATE_COMMENT_ENQUIRY",
  GET_ENQUIRIES_SUCCESS: "GET_ENQUIRIES_SUCCESS",
}

export const masterDataActionType = {
  SET_MASTER_DATA: "SET_MASTER_DATA",
  GET_MASTER_DATA_SUCCESS: "GET_MASTER_DATA_SUCCESS",
}

export const uploadActionType = {
  GET_LIST: "GET_LIST",
  GET_TESTING_REPORTS_SUCCESS: "GET_TESTING_REPORTS_SUCCESS",
}

export const ags4ActionType = {
  IMPORT_SUCCESS: "IMPORT_SUCCESS",
  CHECK_EXIST_SUCCESS: "CHECK_EXIST_SUCCESS"
}