/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "us-east-1_eUMhMzo8d",
    "aws_user_pools_web_client_id": "1rs1cc18cthek7t03tl4a11c7n"
};


export default config;
