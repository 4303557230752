import { userActionType } from "../actionTypes"

// Declaring an initial state object with default values
const initialState = {
  users: [],
  assignable_users: [],
  user: {},
  isAddUserSuccess: "",
  errorAddUser: "",
  isGetListUsersSuccess: false
}

// Defining user reducer as a function with two parameters.
const user = (state = initialState, action) => {
  switch (action.type) {
    // Case for GET_LIST action type.
    case userActionType.GET_LIST:
      return {
        ...state,
        users: action.payload
      }
    // Case for GET_ASSIGNABLE_USERS_LIST action type.
    case userActionType.GET_ASSIGNABLE_USERS_LIST:
      return {
        ...state,
        assignable_users: action.payload
      }
    // Case for ADD_USER action type.
    case userActionType.ADD_USER:
      return {
        ...state
      }
    // Case for ADD_USER_SUCCESS action type.
    case userActionType.ADD_USER_SUCCESS:
      return {
        ...state,
        isAddUserSuccess: action.payload
      }
    // Case for ERROR_ADD_USER action type.
    case userActionType.ERROR_ADD_USER:
      return {
        ...state,
        errorAddUser: action.payload,
      }
    // Case for GET_LIST_USERS_SUCCESS action type.
    case userActionType.GET_LIST_USERS_SUCCESS:
      return {
        ...state,
        isGetListUsersSuccess: action.payload
      }
    // Default fallback case returns the current state.
    default:
      return state
  }
}

// Exporting auth reducer function as default.
export default user
