import { uploadActionType } from "store/actionTypes"

// Setting initial state values.
const initialState = {
  files: [],
  isTestingReportsSuccess: false,
}

// Defining upload reducer as a function with two parameters.
const upload = (state = initialState, action) => {
  switch (action.type) {
    // Case for GET_LIST action type.
    case uploadActionType.GET_LIST:
      return {
        ...state,
        files: action.payload
      }
    // Case for GET_TESTING_REPORTS_SUCCESS action type.
    case uploadActionType.GET_TESTING_REPORTS_SUCCESS:
      return {
        ...state,
        isTestingReportsSuccess: action.payload,
      }
    // Default fallback case returns the current state.
    default:
      return state
  }
}

// Exporting auth reducer function as default.
export default upload