import { combineReducers } from "redux"
import auth from "./auth"
import app from "./app"
import user from "./user"
import alert from "./alert"
import modal from "./modal"
import project from "./project"
import material from "./material"
import testingRequest from "./testingRequest"
import tracking from "./tracking"
import enquiry from "./enquiry"
import masterData from "./masterData"
import upload from "./upload"
import ags4 from "./ags4"
export default combineReducers({
  auth,
  app,
  user,
  alert,
  project,
  material,
  testingRequest,
  modal,
  tracking,
  enquiry,
  masterData,
  upload,
  ags4
})
