import { masterDataActionType } from "../actionTypes"

//Initial state of masterData
const initialState = {
  masterData: {
    materials: [],
    test_groups: []
  },
  isGetMasterDataSuccess: false
}

//Declaring the masterData reducer function
const masterData = (state = initialState, action) => {
  switch (action.type) {
    // Case for SET_MASTER_DATA action type.
    case masterDataActionType.SET_MASTER_DATA:
      return {
        ...state,
        masterData: action.payload,
      }
    // Case for GET_MASTER_DATA_SUCCESS action type.
    case masterDataActionType.GET_MASTER_DATA_SUCCESS:
      return {
        ...state,
        isGetMasterDataSuccess: action.payload,
      }
    // Default fallback case returns the current state.
    default:
      return state
  }
}

// Exporting auth reducer function as default.
export default masterData