import { materialActionType } from "../actionTypes"

// Initial State of the Material
const initialState = {
  materials: [],
}

// Declare Material reducer function
const material = (state = initialState, action) => {
  switch (action.type) {
    // Case for GET_MATERIALS action type.
    case materialActionType.GET_MATERIALS:
      return {
        ...state,
        materials: action.payload,
      }
    // Default fallback case returns the current state.
    default:
      return state
  }
}

// Exporting auth reducer function as default.
export default material